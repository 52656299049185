<template>
  <portal to="dialog_top">
    <!-- Sidebar -->
    <ws-dialog
        v-if="!popup"
        v-model="display"
        :background-color="CONFIG_PARAM_COLOR('styles' , blockStyle , 'bg')"
        :overlay-color="CONFIG_PARAM_COLOR('styles' , 'dark_2' , 'bg') + '66'"
        no-navigation
        fullscreen
        fullscreen-width="550"
        fullscreen-align="right"
        fill-height
        blank
        no-padding
        not-centered
    >

      <template #header>
        <v-sheet
            @click="display = false "
            :color="CONFIG_PARAM_COLOR('styles',blockStyle,'ux_arrow_bg')"
            class="pointer d-flex align-center justify-center"
            style="border-radius: 50%; position: absolute; right: 20px ; top : 20px; ; z-index: 99"
            :height="!SM ? 52 : 34"
            :width="!SM ? 52 : 34"
        >
          <v-icon :small="SM " :color="CONFIG_PARAM_COLOR('styles',blockStyle || 'light_1','ux_arrow')" >mdi-close</v-icon>
        </v-sheet>
      </template>
      <v-sheet
          :color="CONFIG_PARAM_COLOR('styles' , blockStyle , 'bg')"
          class="fill-height overflow-y-auto"
      >
        <v-img aspect-ratio="1.5"  :src="item.img" />

        <div class="px-12">
          <h3 :style="CONFIG_TEXT_CSS(blockStyle , parentElement , 'title')" class="pt-6" >{{ item.title }}</h3>
          <h4 :style="CONFIG_TEXT_CSS(blockStyle , parentElement , 'text')" class="mt-2">{{ item.text }}</h4>
        </div>
        <v-divider :style="`border-color : ${CONFIG_PARAM_COLOR('styles' , blockStyle , `${parentElement}_title`  )}`" class="my-6" />
        <div class="px-12 pb-8">

          <div :id="`slider_sidebar_${uuid}`" v-html="item.additional_text"></div>

        </div>
      </v-sheet>

    </ws-dialog>
    <!-- Popup Dialog -->
    <ws-dialog
        v-else-if="popup"
        v-model="display"
        blank
        no-padding
        no-navigation
        border-radius="0"
        width="550"
        portal
        :background-color="CONFIG_PARAM_COLOR('styles' , blockStyle , 'bg')"
        :overlay-color="CONFIG_PARAM_COLOR('styles' , 'dark_2' , 'bg') + '66'"
    >
      <template #header>
        <div class="pa-6 d-flex justify-space-between">
          <h2 :style="CONFIG_TEXT_CSS(blockStyle , 'h4' )">{{ item.title }}</h2>
          <v-btn
              @click="display = false"
              large icon :color="CONFIG_PARAM_COLOR('styles' , blockStyle , 'button_text_secondary')">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>
      </template>

      <avalon-html-viewer
          class="pa-6 pt-0 pb-9"
          :text="item.additional_text"
          :block-style="blockStyle"
      />

    </ws-dialog>
  </portal>
</template>

<script>
export default {
  name: "sliderSidebar",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    item : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    },
    tiles : {
      type : Boolean,
      default : false
    },
    popup : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      uuid : null,
      parentElement : 'slider1',
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    }
  },
  beforeMount() {
    if ( this.tiles ) {
      this.parentElement = 'tiles'
    }
    this.uuid = this.$uuid.v4()
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    setTimeout(()=> {
      this.PROCESS_TEXT_STYLES(`slider_sidebar_${this.uuid}` , this.blockStyle)
    },5)
  }
}
</script>

<style scoped>

</style>